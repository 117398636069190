/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;500;700;900&display=swap');
@import "./assets/css/icomoon.css";
@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light-border.css";
@import "tippy.js/dist/border.css";

html, body { height: 100%; }
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    
}
.cdk-global-scrollblock {
    top: 0 !important;
}
.flex {
    display: flex;
}
.flex-center {
    display: flex;
    align-items: center;
}
button {
    outline: none;
    border: none;
    cursor: pointer;
}
button:disabled {
    cursor: default;
}
p {
    margin: 0;
}
img {
    object-fit: contain;
    border: 0;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
ul li {
    list-style: none;
}
.mobile {
    display: none;
}
.no-mobile {
    display: block;
}
.my-account-bg {
    background-color: #233253;
    padding-top: 68px;
    min-height: calc(100% - 182px);
}
.my-login {
    padding-top: 68px;
    background-color: #f0f0f0;
    min-height: calc(100% - 182px);
}
.surrender-bg {
    background-color: #d5effc;
    padding-top: 10px;
    min-height: calc(100% - 182px);
}
.my-account-bg-full {
    min-height: 100%;
    padding-top: 10px;
}
.login-bg {
    background-color: #f0f0f0;
    padding: 0; 
}
.custom-link-bg {
    background-color: #f0f0f0;
    padding-top: 68px;
    min-height: calc(100% - 182px);
}
.mat-card {
    font-family: 'Poppins', sans-serif;
}
.cursor-point {
    cursor: pointer;
}
.app-loader {
    position: static !important;
    height: 100%;
}
.app-loader ng2-loading-spinner .backdrop,
.app-loader ng2-loading-spinner .wrapper {
    position: fixed !important;
}
.app-loader ng2-loading-spinner .backdrop {
    z-index: 99998 !important;
}
.app-loader ng2-loading-spinner .wrapper {
    z-index: 99999 !important;
}
.mat-snack-bar-container {
    border: 1px solid #0003;
    color: #000000;
    font-weight: 400 !important;
    max-width: 530px !important;
    border-radius: 5px !important;
    padding: 15px 30px 15px 42px !important;
}
.success-snackbar.mat-snack-bar-container {
    background: #E7F9EB;
}
.error-snackbar.mat-snack-bar-container {
    background: #ffe8e9;
}
.success-snackbar .mat-simple-snackbar {
    background: url(/assets/green-tick.svg) no-repeat;
    background-position: 0 2px;
}
.error-snackbar .mat-simple-snackbar {
    background: url(/assets/close-red.svg) no-repeat;
    background-position: 3px 3px;
    background-size: 20px 20px;
}
.success-snackbar .mat-simple-snackbar span,
.error-snackbar .mat-simple-snackbar span {
    margin-left: 30px;
}
.error-snackbar .mat-simple-snackbar span {
    margin-left: 35px;
}
.success-snackbar .mat-simple-snackbar .mat-button-wrapper,
.error-snackbar .mat-simple-snackbar .mat-button-wrapper {
    background: url(/assets/cross.svg) no-repeat;
    color: transparent;
    position: relative;
    z-index: 99;
}
.success-snackbar .mat-simple-snackbar-action button,
.error-snackbar .mat-simple-snackbar-action button {
    padding: 0;
}
.success-snackbar .mat-simple-snackbar-action,
.error-snackbar .mat-simple-snackbar-action {
    color: #000000;
}
.success-snackbar .mat-simple-snackbar,
.error-snackbar .mat-simple-snackbar {
    font-size: 16px !important;
    line-height: 24px;
}
.cdk-overlay-container {
    position: fixed ! important;
}
.popup-content-height {
    max-height: 65vh;
    overflow-y: auto;
}
.popup-content-height::-webkit-scrollbar {
    width: 7px;
}
.popup-content-height::-webkit-scrollbar-thumb {
    border-radius: 11px;
    background-color: #c7c7c7;
}
.mat-form-field-label .mat-placeholder-required {
    opacity: 0;
}


.tippy-box {
    background-color: #ffffff;
    color: #233253;    
    border: 2px solid #1259ae;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2), 0px 2px 8px 0px rgba(0, 0, 0, 0.18);
}
.tippy-box .tippy-content {
    color: #233253;
    font-family: 'Poppins', sans-serif;
    padding: 3px 6px;
}
.tippy-box .tippy-content a {
    font-weight: 600;
}
.tippy-box .tippy-content .prem-progress-dtls {
    margin: 14px auto;
    max-width: 300px;
}
.tippy-box .tippy-content .prem-progress-dtls .detail {
    justify-content: space-between;
}
.tippy-box .tippy-content .prem-progress-note {   
    text-align: center; 
}

.tippy-box[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: transparent;
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: transparent;
}
.tippy-box[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: transparent;
}
.tippy-box[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: transparent;
}

.tippy-box[data-theme~='light-border-prem-outlook'] .tippy-content {
    font-size: 12px;
    font-weight: 500;
}
.tippy-box[data-theme~='light-border-pan'] .tippy-content {
    width: 250px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}


.search-placeholder.rotation {
    animation: rotation 16s ease-in-out;
    animation-iteration-count: infinite;
}
@keyframes rotation {
    0%  { top: 25px }
    15% { top: 0px }
    30% { top: -25px }
    45% { top: -50px }
    60% { top: -75px }
    75% { top: -100px }
    90% { top: -125px }
    100% { top: -150px }
}
.myacc-app-highlight {
    /* background-color: #ed1c2436; */
    animation: highlight 1s ease-in-out;
    animation-iteration-count: 6;
}
@keyframes highlight {
    0% { background-color: transparent; }      
   25% { background-color: #ed1c2436; }
   50% { background-color: transparent; }
   75% { background-color: #ed1c2436; }
   100% { background-color: #ed1c2436; }
}
.myacc-app-pulse {
    animation: pulse;
    animation-duration: 1s;
    animation-iteration-count: 6;
}
.infinite-pulse {
    animation: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
@keyframes pulse {
     /* 0% { transform: scale(1.0); }    
    /*25% { transform: scale(1.1); }*/
    50% { transform: scale(1.1); } 
    /*75% { transform: scale(1.3); }*/         
    100% { transform: scale(1.0); } 
}
/*Device Specific CSS Starts*/

@media (max-width: 767px) { 
    .mobile {
        display: block;
    }
    .no-mobile {
        display: none;
    }
    .my-account-bg {
        padding-top: 0;
        min-height: calc(100% - 218px);
    }
    .chatbot-body iframe.life-ai-iframe-service-class {
        bottom: 85px;
        width: 260px;
    }
    .chatbot-body iframe.life-ai-iframe-service-opened {
        bottom: 0 ;
    }
}

/*hni users CSS Starts*/
.hni-bg,
.hni-popups .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    background: rgb(95,77,144) ;
    background-image: url('/assets/mask-bg.png');
    background-image: url('/assets/mask-bg.png'), linear-gradient(90deg, rgba(95,77,144,1) 0%, rgba(129,92,159,1) 20%, rgba(144,118,174,1) 60%, rgba(156,137,185,1) 100%);
    background-repeat: no-repeat;
    background-position: 0 0;
}
.hni-popups .mat-overlay-transparent-backdrop {
    background: transparent !important;
}
.customer-menu-backdrop {
    background: rgba(0,0,0,0.6) !important;
    top: 68px;
}
.hni-footer-section {
    background: linear-gradient(90deg, rgba(95,77,144,1) 0%, rgba(129,92,159,1) 20%, rgba(144,118,174,1) 60%, rgba(156,137,185,1) 100%);
}
.hni-text-white {
    color: #ffffff ! important;
}
.hni-top-btns {
    color: #ffffff !important;
    background: #5f2e85 !important;
    border: 1px solid #5f2e85 !important;
    border-radius: 5px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}
.my-policy-height {
    height: 35px;
}
.select-policy-mob-hni {
    color: #ffffff !important;
    background: #5f2e85 !important;
    border: 1px solid #5f2e85 !important;
}
.sr-text,
.hni-breadcrumb button,
.sr-details .hni-breadcrumb button:after,
.policy-details .hni-breadcrumb button:after {
    color: #ddc9ec !important;
}
.sr-details .hni-breadcrumb button:before,
.policy-details .hni-breadcrumb button:before {
    background: url(/assets/hni_home.svg) no-repeat left top !important;
}
.hni-popups-header,
.hni-get-touch {
    background: #5f2e85 !important;
}
.hni-get-touch .info-label {
    color: #9fb1c1 !important;
}
.hni-fund-title,
.header-section .header-menus li.hni-fund-title a,
.header-section .header-menus li.hni-active-menu a  {
    color: #5f2e85 !important;
}
.hni-close-dialog:hover,
.hni-close-dialog:active,
.hnipasa-close-dialog:hover,
.hnipasa-close-dialog:active {
    color: #322339 !important;
}
.hni-close-dialog:hover img,
.hni-close-dialog:active img,
.hnilanding-close-dialog:hover,
.hnilanding-close-dialog:active {
    filter: invert(32%) sepia(24%) saturate(4148%) hue-rotate(247deg) brightness(30%) contrast(120%) !important;
}
.header-section .header-menus li.hni-active-menu a:after,
.header-section .end-header .hni-active-customer a:after,
.header-section .end-header .notification-logo.hni-active-customer:after  {
	border-color: transparent transparent #5f2e85 transparent !important;
}
.header-section .header-menus li.hni-active-menu a:before,
.header-section .end-header .customer-header.menu a:before {
	background: #5f2e85 !important;
} 

.header-section .header-menus .policy-menu.hni-fund-title a .menu-icon:before,
.header-section .header-menus .policy-menu.hni-active-menu a .menu-icon:before,
.header-section .header-menus .policy-menu.hni-fund-title a:hover .menu-icon:before {
	background-image: url('/assets/hni_my_polices.svg') !important;
}
.header-section .header-menus .account-menu.hni-fund-title a .menu-icon:before,
.header-section .header-menus .account-menu.hni-active-menu a .menu-icon:before,
.header-section .header-menus .account-menu.hni-fund-title  a:hover .menu-icon:before {
	background-image: url('/assets/hni_my_acct.svg') !important;
}
.header-section .header-menus .downloads-menu.hni-fund-title a .menu-icon:before,
.header-section .header-menus .downloads-menu.hni-active-menu a .menu-icon:before,
.header-section .header-menus .downloads-menu.hni-fund-title a:hover .menu-icon:before {
	background-image: url('/assets/hni_download.svg') !important;
}
.hni-header-logo {
    max-width: 161px !important;
    margin-right: 20px !important;
    padding: 18px 0 10px 0 !important;
}
.hni-header-logo img {
    max-width: none;
}
/*Device Specific CSS Starts*/

@media (max-width: 767px) { 
    .my-policy-height {
        height: 40px;
    }
    .hni-header-logo {
        max-width: 96px !important;
        padding: 10px 0 !important;
    }
    .hni-popups-header {
        background: #ffffff !important;
    }
    .customer-menu-backdrop {
        top: 74px;
    }
}

@media (min-width: 993px) and (max-width: 1279px) { 
    .hni-header-logo {
        margin-right: 15px !important;
    }
}
@media (min-width: 767px) and (max-width: 993px) { 
    .my-account-bg {
        padding-top: 0px;
    }
}
/*hni users CSS Ends*/