@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?hkqc0c');
  src:  url('../fonts/icomoon.eot?hkqc0c#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?hkqc0c') format('truetype'),
    url('../fonts/icomoon.woff?hkqc0c') format('woff'),
    url('../fonts/icomoon.svg?hkqc0c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-acc-type:before {
  content: "\e900";
  color: #125a9e;
}
.icon-active_border:before {
  content: "\e901";
  color: #ed1c24;
}
.icon-add-person-red:before {
  content: "\e902";
  color: #ed1c24;
}
.icon-angle_down:before {
  content: "\e903";
}
.icon-angle_down_blue:before {
  content: "\e904";
}
.icon-angle_down_red:before {
  content: "\e905";
}
.icon-angle_down_white:before {
  content: "\e906";
}
.icon-angle_right_white:before {
  content: "\e907";
}
.icon-angle-up:before {
  content: "\e908";
}
.icon-angle-up-red:before {
  content: "\e909";
}
.icon-arrow:before {
  content: "\e90a";
}
.icon-arrow_down_white_large:before {
  content: "\e90b";
}
.icon-arrow-down-red-1:before {
  content: "\e90c";
}
.icon-arrow-down-red-2:before {
  content: "\e90d";
}
.icon-arrow-down-white:before {
  content: "\e90e";
}
.icon-arrow-up-white:before {
  content: "\e90f";
}
.icon-bank:before {
  content: "\e910";
  color: #fff;
}
.icon-bank-blue:before {
  content: "\e911";
}
.icon-beneficiaries:before {
  content: "\e912";
  color: #fff;
}
.icon-branch-name:before {
  content: "\e913";
}
.icon-building_white:before {
  content: "\e914";
  color: #fff;
}
.icon-calendar_blue:before {
  content: "\e915";
}
.icon-calendar-time:before {
  content: "\e916";
}
.icon-calender:before {
  content: "\e917";
  color: #125a9e;
}
.icon-call:before {
  content: "\e918";
}
.icon-call-blue:before {
  content: "\e919";
}
.icon-call-me:before {
  content: "\e91a";
  color: #125a9e;
}
.icon-camera:before {
  content: "\e91b";
  color: #69d2ff;
}
.icon-card:before {
  content: "\e91c";
  color: #125a9e;
}
.icon-chat_icon:before {
  content: "\e91d";
  color: #ed1c24;
}
.icon-claim:before {
  content: "\e91e";
}
.icon-close:before {
  content: "\e91f";
  color: #fff;
}
.icon-close-btn:before {
  content: "\e920";
}
.icon-close-red:before {
  content: "\e921";
  color: #ff5a60;
}
.icon-covid:before {
  content: "\e922";
  color: #fff;
}
.icon-cross:before {
  content: "\e923";
}
.icon-cross_circle_blue:before {
  content: "\e924";
  color: #233253;
}
.icon-cross_white:before {
  content: "\e925";
}
.icon-date:before {
  content: "\e926";
}
.icon-datepicker:before {
  content: "\e927";
}
.icon-deact:before {
  content: "\e928";
}
.icon-document_icon:before {
  content: "\e929";
  color: #ed1c24;
}
.icon-double-arrow:before {
  content: "\e92a";
}
.icon-download:before {
  content: "\e92b";
}
.icon-download_active:before {
  content: "\e92c";
}
.icon-download_icon:before {
  content: "\e92d";
  color: #ed1c24;
}
.icon-dropdown-circle:before {
  content: "\e92e";
}
.icon-dropdown-circle-fill:before {
  content: "\e92f";
  color: #ed1c24;
}
.icon-ecg:before {
  content: "\e930";
  color: #233253;
}
.icon-edit:before {
  content: "\e931";
  color: #ed1c24;
}
.icon-edit_btn:before {
  content: "\e932";
  color: #ed1c24;
}
.icon-edit-pol:before {
  content: "\e933";
}
.icon-email:before {
  content: "\e934";
}
.icon-envelope_white:before {
  content: "\e935";
  color: #fff;
}
.icon-file_icon:before {
  content: "\e936";
  color: #fff;
}
.icon-file-check:before {
  content: "\e937";
  color: #fff;
}
.icon-file-medical:before {
  content: "\e938";
  color: #fff;
}
.icon-filter .path1:before {
  content: "\e939";
  color: rgb(255, 255, 255);
  opacity: 0.0700;
}
.icon-filter .path2:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.7;
}
.icon-filter .path3:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.7;
}
.icon-filter .path4:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.7;
}
.icon-gender:before {
  content: "\e93d";
  color: #125a9e;
}
.icon-green_circle-tick:before {
  content: "\e93e";
  color: #43b45a;
}
.icon-green-tick:before {
  content: "\e93f";
  color: #43b45a;
}
.icon-headphone:before {
  content: "\e940";
  color: #233253;
}
.icon-home:before {
  content: "\e941";
  color: #7b8498;
}
.icon-icici:before {
  content: "\e942";
}
.icon-ifsc:before {
  content: "\e943";
}
.icon-india:before {
  content: "\e944";
}
.icon-info:before {
  content: "\e945";
  color: #ff5a60;
}
.icon-info_active:before {
  content: "\e946";
  color: #0083c3;
}
.icon-info-red:before {
  content: "\e947";
  color: #ff5a60;
}
.icon-info-red-1:before {
  content: "\e948";
  color: #ff5a60;
}
.icon-Investment-icon .path1:before {
  content: "\e949";
  color: rgb(0, 0, 0);
}
.icon-Investment-icon .path2:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}
.icon-Investment-icon .path3:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(35, 50, 83);
}
.icon-Investment-icon .path4:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(35, 50, 83);
}
.icon-Investment-icon .path5:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-Investment-icon .path6:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(35, 50, 83);
}
.icon-Investment_icon .path1:before {
  content: "\e94f";
  color: rgb(0, 0, 0);
}
.icon-Investment_icon .path2:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}
.icon-Investment_icon .path3:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(35, 50, 83);
}
.icon-Investment_icon .path4:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(35, 50, 83);
}
.icon-Investment_icon .path5:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-Investment_icon .path6:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(35, 50, 83);
}
.icon-li-style:before {
  content: "\e955";
  color: #125a9e;
}
.icon-location:before {
  content: "\e956";
}
.icon-logout:before {
  content: "\e957";
}
.icon-long_arrow_red:before {
  content: "\e958";
  color: #ed1c24;
}
.icon-martial:before {
  content: "\e959";
  color: #125a9e;
}
.icon-microscope:before {
  content: "\e95a";
  color: #233253;
}
.icon-mobile:before {
  content: "\e95b";
}
.icon-mobile_white:before {
  content: "\e95c";
  color: #fff;
}
.icon-my_acct:before {
  content: "\e95d";
}
.icon-my_polices:before {
  content: "\e95e";
}
.icon-my_policies_active:before {
  content: "\e95f";
}
.icon-notification .path1:before {
  content: "\e960";
  color: rgb(35, 50, 83);
}
.icon-notification .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(35, 50, 83);
}
.icon-notification .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}
.icon-pay:before {
  content: "\e963";
}
.icon-pay-hist:before {
  content: "\e964";
  color: #fff;
}
.icon-pen-red:before {
  content: "\e965";
  color: #ed1c24;
}
.icon-person:before {
  content: "\e966";
}
.icon-personal:before {
  content: "\e967";
}
.icon-phone_white:before {
  content: "\e968";
  color: #fff;
}
.icon-plus-red:before {
  content: "\e969";
}
.icon-plus-red-circle:before {
  content: "\e96a";
}
.icon-policy-icon:before {
  content: "\e96b";
}
.icon-policy_icon1 .path1:before {
  content: "\e96c";
  color: rgb(0, 0, 0);
}
.icon-policy_icon1 .path2:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon1 .path3:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon1 .path4:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon1 .path5:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon1 .path6:before {
  content: "\e971";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path7:before {
  content: "\e972";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path8:before {
  content: "\e973";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path9:before {
  content: "\e974";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path10:before {
  content: "\e975";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path11:before {
  content: "\e976";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path12:before {
  content: "\e977";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path13:before {
  content: "\e978";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path14:before {
  content: "\e979";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path15:before {
  content: "\e97a";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path16:before {
  content: "\e97b";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path17:before {
  content: "\e97c";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path18:before {
  content: "\e97d";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path19:before {
  content: "\e97e";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path20:before {
  content: "\e97f";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path21:before {
  content: "\e980";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path22:before {
  content: "\e981";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path23:before {
  content: "\e982";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path24:before {
  content: "\e983";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path25:before {
  content: "\e984";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path26:before {
  content: "\e985";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path27:before {
  content: "\e986";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path28:before {
  content: "\e987";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path29:before {
  content: "\e988";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path30:before {
  content: "\e989";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path31:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(249, 253, 255);
}
.icon-policy_icon1 .path32:before {
  content: "\e98b";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon1 .path33:before {
  content: "\e98c";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path1:before {
  content: "\e98d";
  color: rgb(0, 0, 0);
}
.icon-policy_icon2 .path2:before {
  content: "\e98e";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path3:before {
  content: "\e98f";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path4:before {
  content: "\e990";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path5:before {
  content: "\e991";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path6:before {
  content: "\e992";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path7:before {
  content: "\e993";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path8:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon2 .path9:before {
  content: "\e995";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path10:before {
  content: "\e996";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path11:before {
  content: "\e997";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path12:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon2 .path13:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon2 .path14:before {
  content: "\e99a";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path15:before {
  content: "\e99b";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path16:before {
  content: "\e99c";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon2 .path17:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon2 .path18:before {
  content: "\e99e";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path1:before {
  content: "\e99f";
  color: rgb(0, 0, 0);
}
.icon-policy_icon3 .path2:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon3 .path3:before {
  content: "\e9a1";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path4:before {
  content: "\e9a2";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path5:before {
  content: "\e9a3";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path6:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(237, 28, 36);
}
.icon-policy_icon3 .path7:before {
  content: "\e9a5";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path8:before {
  content: "\e9a6";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path9:before {
  content: "\e9a7";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path10:before {
  content: "\e9a8";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path11:before {
  content: "\e9a9";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path12:before {
  content: "\e9aa";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path13:before {
  content: "\e9ab";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path14:before {
  content: "\e9ac";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path15:before {
  content: "\e9ad";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path16:before {
  content: "\e9ae";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path17:before {
  content: "\e9af";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path18:before {
  content: "\e9b0";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path19:before {
  content: "\e9b1";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path20:before {
  content: "\e9b2";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path21:before {
  content: "\e9b3";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path22:before {
  content: "\e9b4";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path23:before {
  content: "\e9b5";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon3 .path24:before {
  content: "\e9b6";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon4 .path1:before {
  content: "\e9b7";
  color: rgb(0, 0, 0);
}
.icon-policy_icon4 .path2:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon4 .path3:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon4 .path4:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon4 .path5:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon4 .path6:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon4 .path7:before {
  content: "\e9bd";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon4 .path8:before {
  content: "\e9be";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon4 .path9:before {
  content: "\e9bf";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon4 .path10:before {
  content: "\e9c0";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon4 .path11:before {
  content: "\e9c1";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon4 .path12:before {
  content: "\e9c2";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon4 .path13:before {
  content: "\e9c3";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon4 .path14:before {
  content: "\e9c4";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon4 .path15:before {
  content: "\e9c5";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon4 .path16:before {
  content: "\e9c6";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path1:before {
  content: "\e9c7";
  color: rgb(0, 0, 0);
}
.icon-policy_icon5 .path2:before {
  content: "\e9c8";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path3:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(247, 252, 255);
}
.icon-policy_icon5 .path4:before {
  content: "\e9ca";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path5:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(247, 252, 255);
}
.icon-policy_icon5 .path6:before {
  content: "\e9cc";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path7:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(247, 252, 255);
}
.icon-policy_icon5 .path8:before {
  content: "\e9ce";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path9:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(247, 252, 255);
}
.icon-policy_icon5 .path10:before {
  content: "\e9d0";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path11:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(247, 252, 255);
}
.icon-policy_icon5 .path12:before {
  content: "\e9d2";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path13:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(247, 252, 255);
}
.icon-policy_icon5 .path14:before {
  content: "\e9d4";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path15:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(247, 252, 255);
}
.icon-policy_icon5 .path16:before {
  content: "\e9d6";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path17:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(247, 252, 255);
}
.icon-policy_icon5 .path18:before {
  content: "\e9d8";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path19:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(241, 249, 255);
}
.icon-policy_icon5 .path20:before {
  content: "\e9da";
  margin-left: -1em;
  color: rgb(241, 249, 255);
}
.icon-policy_icon5 .path21:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon5 .path22:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(241, 249, 255);
}
.icon-policy_icon5 .path23:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon5 .path24:before {
  content: "\e9de";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path25:before {
  content: "\e9df";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path26:before {
  content: "\e9e0";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path27:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-policy_icon5 .path28:before {
  content: "\e9e2";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path29:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(247, 252, 255);
}
.icon-policy_icon5 .path30:before {
  content: "\e9e4";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path31:before {
  content: "\e9e5";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path32:before {
  content: "\e9e6";
  margin-left: -1em;
  color: none;
}
.icon-policy_icon5 .path33:before {
  content: "\e9e7";
  margin-left: -1em;
  color: none;
}
.icon-policy-blue:before {
  content: "\e9e8";
  color: #125a9e;
}
.icon-policy-white:before {
  content: "\e9e9";
}
.icon-premium:before {
  content: "\e9ea";
}
.icon-radio_tick:before {
  content: "\e9eb";
}
.icon-save:before {
  content: "\e9ec";
}
.icon-savings .path1:before {
  content: "\e9ed";
  color: rgb(0, 0, 0);
}
.icon-savings .path2:before {
  content: "\e9ee";
  margin-left: -1em;
  color: none;
}
.icon-savings .path3:before {
  content: "\e9ef";
  margin-left: -1em;
  color: none;
}
.icon-savings .path4:before {
  content: "\e9f0";
  margin-left: -1em;
  color: none;
}
.icon-savings .path5:before {
  content: "\e9f1";
  margin-left: -1em;
  color: none;
}
.icon-savings .path6:before {
  content: "\e9f2";
  margin-left: -1em;
  color: none;
}
.icon-savings .path7:before {
  content: "\e9f3";
  margin-left: -1em;
  color: none;
}
.icon-savings .path8:before {
  content: "\e9f4";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-savings .path9:before {
  content: "\e9f5";
  margin-left: -1em;
  color: none;
}
.icon-savings .path10:before {
  content: "\e9f6";
  margin-left: -1em;
  color: none;
}
.icon-savings .path11:before {
  content: "\e9f7";
  margin-left: -1em;
  color: none;
}
.icon-savings .path12:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-savings .path13:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-savings .path14:before {
  content: "\e9fa";
  margin-left: -1em;
  color: none;
}
.icon-savings .path15:before {
  content: "\e9fb";
  margin-left: -1em;
  color: none;
}
.icon-savings .path16:before {
  content: "\e9fc";
  margin-left: -1em;
  color: none;
}
.icon-savings .path17:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(105, 210, 255);
}
.icon-savings .path18:before {
  content: "\e9fe";
  margin-left: -1em;
  color: none;
}
.icon-send_icon:before {
  content: "\e9ff";
  color: #ed1c24;
}
.icon-setting:before {
  content: "\ea00";
}
.icon-status-ok:before {
  content: "\ea01";
  color: #125a9e;
}
.icon-sum:before {
  content: "\ea02";
}
.icon-term:before {
  content: "\ea03";
}
.icon-tick:before {
  content: "\ea04";
  color: #125a9e;
}
.icon-tick_white:before {
  content: "\ea05";
}
.icon-tick_white_large:before {
  content: "\ea06";
}
.icon-tick-small:before {
  content: "\ea07";
}
.icon-upload:before {
  content: "\ea08";
  color: #69d2ff;
}
.icon-user:before {
  content: "\ea09";
  color: #fff;
}
.icon-users:before {
  content: "\ea0a";
  color: #fff;
}
.icon-users-alt-1-1:before {
  content: "\ea0b";
  color: #fff;
}
.icon-whatsapp:before {
  content: "\ea0c";
  color: #125a9e;
}
.icon-x-ray:before {
  content: "\ea0d";
  color: #233253;
}
